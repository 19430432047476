import { useState, memo } from 'react';
import {
    TypographyScale,
    SystemIcon,
    ESystemIconNames,
    RadioGroup,
    Radio,
} from '@mdb/flora';

import { FilterItem } from '@mdb/devcenter-components';

import { RadioFilterGroupProps } from './types';
import { titleStyles, itemsStyles } from './styles';
import { SortByType } from '../search/types';

const FILTER_STEP = 5;

const RadioFilterGroup: React.FunctionComponent<RadioFilterGroupProps> = memo(
    ({
        className,
        title,
        items,
        filters,
        isMobile = false,
        setSort = () => null,
        sortBy,
    }) => {
        const SORT_OPTIONS: Record<'latest' | 'highestRated', SortByType> = {
            latest: 'Latest',
            highestRated: 'Highest Rated',
        };

        const options: { key: keyof typeof SORT_OPTIONS; label: SortByType }[] =
            [
                { key: 'latest', label: SORT_OPTIONS.latest },
                { key: 'highestRated', label: SORT_OPTIONS.highestRated },
            ];

        // Mapping sortBy value to corresponding internal key
        const mappedSortBy = Object.keys(SORT_OPTIONS).find(
            key => SORT_OPTIONS[key as keyof typeof SORT_OPTIONS] === sortBy
        ) as keyof typeof SORT_OPTIONS;

        const [expanded, setExpanded] = useState<boolean>(
            isMobile ? false : true
        );

        const onExpand = () => {
            setExpanded(!expanded);
        };

        const mobileFilterList = (() => {
            if (!title) return null;
            if (sortBy) {
                return (
                    <TypographyScale variant="body1" color="mark">
                        {sortBy}
                    </TypographyScale>
                );
            }
            const getFilterNames = (items: FilterItem[]): string[] => {
                let filterList: string[] = [];

                items.forEach(item => {
                    if (filters.includes(item)) {
                        filterList.push(item.name);
                    }
                    if (item.subFilters) {
                        filterList = filterList.concat(
                            getFilterNames(item.subFilters)
                        );
                    }
                });
                return filterList;
            };
            const filterNames = getFilterNames(items);

            const filterString =
                filterNames.length < FILTER_STEP
                    ? filterNames.join(', ')
                    : `${filterNames.slice(0, FILTER_STEP).join(', ')} +${
                          filterNames.length - FILTER_STEP
                      }`;
            return (
                <TypographyScale variant="body1" color="mark">
                    {filterString}
                </TypographyScale>
            );
        })();

        return (
            <div className={className}>
                {!!title && (
                    <div onClick={onExpand}>
                        <div sx={titleStyles}>
                            <TypographyScale variant="body1">
                                {title}
                            </TypographyScale>
                            <SystemIcon
                                size="small"
                                strokeWeight="medium"
                                name={ESystemIconNames.CHEVRON_DOWN}
                                sx={{
                                    transform: expanded
                                        ? 'rotate(180deg)'
                                        : null,
                                }}
                            />
                        </div>
                        {isMobile && !expanded && mobileFilterList}
                    </div>
                )}

                {expanded && (
                    <div sx={itemsStyles(title)}>
                        {sortBy && (
                            <RadioGroup
                                name={title || 'RadioGroup'}
                                onChange={(value: string) =>
                                    setSort(
                                        SORT_OPTIONS[
                                            value as 'latest' | 'highestRated'
                                        ]
                                    )
                                }
                                // Set defaultChecked using the mapped key
                                defaultChecked={mappedSortBy}
                            >
                                {options.map(({ key, label }, index) => (
                                    <Radio key={index} value={key}>
                                        {label}
                                    </Radio>
                                ))}
                            </RadioGroup>
                        )}
                    </div>
                )}
            </div>
        );
    }
);
RadioFilterGroup.displayName = 'RadioFilterGroup';
export default RadioFilterGroup;
